import { Injectable } from '@angular/core';
import { getOnboardingCashback } from '@features/onboarding/data';
import { GetDynamicIncentiveProgramUseCase } from 'app/core/usecases/dynamic-incentive-program/get-dynamic-incentive-program.usecase';
import { GetOnboardingOrdersTrackingUseCase } from 'app/core/usecases/get-onboarding-orders-tracking-usecase';
import { ONBOARDING_MAX_ORDER_PROGRESS } from 'app/presentation/shared/constants/onboarding';
import { BasePresenter } from 'src/app/presentation/base/base.presenter';
import { ChallengesWidgetSideEffect } from './challenges-widget.sideEffects';
import { ChallengesWidgetViewEvents } from './challenges-widget.viewEvents';
import { ChallengesWidgetViewState } from './challenges-widget.viewState';

@Injectable()
export class ChallengesWidgetPresenter extends BasePresenter<
  ChallengesWidgetViewState,
  ChallengesWidgetViewEvents,
  ChallengesWidgetSideEffect
> {
  constructor(
    private _getDynamicIncentiveProgramUseCase: GetDynamicIncentiveProgramUseCase,
    private _getOnboardingOrdersTrackingUseCase: GetOnboardingOrdersTrackingUseCase,
  ) {
    super();
  }

  protected defaultViewState(): ChallengesWidgetViewState {
    return {
      challengesLoaded: false,
      cashbackLoaded: false,
      challenges: [],
      cashback: {
        targetOrders: 0,
        achievedOrders: 0,
        achieved: false,
        reward: 0,
      },
    };
  }

  protected onViewEvent(event: ChallengesWidgetViewEvents): void {
    switch (event.type) {
      case 'Init': {
        this.getChallenges();
        this.getCashbackProgress();
        break;
      }
    }
  }

  public getChallenges(): void {
    this.add(
      this._getDynamicIncentiveProgramUseCase.execute().subscribe({
        next: (dynamicIncentiveData) => {
          this.merge({
            challenges: dynamicIncentiveData,
            challengesLoaded: true,
          });
        },
        error: () => {
          this.merge({
            challenges: [],
            challengesLoaded: true,
          });
        },
      }),
    );
  }

  public getCashbackProgress(): void {
    this.add(
      this._getOnboardingOrdersTrackingUseCase.execute().subscribe({
        next: (ordersTracking) => {
          this.merge({
            cashback: {
              targetOrders: ONBOARDING_MAX_ORDER_PROGRESS,
              achievedOrders: ordersTracking.orders?.length,
              achieved: ordersTracking.orders?.length >= ONBOARDING_MAX_ORDER_PROGRESS,
              reward: getOnboardingCashback(),
            },
            cashbackLoaded: true,
          });
        },
        error: () => {
          this.merge({
            cashbackLoaded: true,
          });
        },
      }),
    );
  }
}

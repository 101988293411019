<div class="challenges-container">
  <img
    *ngIf="isCarnival"
    class="carnival-icon"
    src="{{ assetsPath + 'carnival-tent.svg' }}"
    alt="Carnival Tent"
  />

  <div class="content">
    <div class="title-container">
      <p class="body1--bold">{{ localizedValue(activeChallenge.name) }}</p>
      <a [href]="incentiveUrl" (click)="trackGoToIncentiveUrl()">
        <span class="caption2--medium">{{
          trans(
            challenges?.length > 1
              ? 'DYNAMIC_INCENTIVE.LANDING.MORE_CHALLENGES'
              : 'DYNAMIC_INCENTIVE.LANDING.CHALLENGES',
            {
              numberOfChallenges: challenges.length - 1
            }
          )
        }}</span>
        <img
          [ngClass]="{ flip: isRTL }"
          src="{{ assetsPath + 'arrow-right.svg' }}"
          alt="arrow icon"
        />
      </a>
    </div>

    <div class="details">
      <!-- IN_PROGRESS -->

      <ng-container *ngIf="inProgress || isOffered || isCarnival; else finishedContainer">
        <div class="in-progress-container">
          <div class="in-progress-container_item">
            <img src="{{ assetsPath + 'clock.svg' }}" alt="clock icon" />
            <p class="caption1--regular">
              {{
                trans('DYNAMIC_INCENTIVE.LANDING.DATE', {
                  days: remainingTime?.days,
                  hours: remainingTime?.hours,
                  minutes: remainingTime?.minutes
                })
              }}
            </p>
          </div>

          <div *ngIf="inProgress && !isCarnival" class="in-progress-container_item">
            <app-circular-progress-bar
              [percentage]="progressPercentage"
            ></app-circular-progress-bar>
            <p class="caption1--regular">
              {{
                trans('DYNAMIC_INCENTIVE.LANDING.ORDERS_FULFILLED', {
                  ordersAchieved: activeChallenge.challengeResult?.numOfAchievedOrders,
                  targetOrders: activeChallenge.maxNumberOfOrders
                })
              }}
            </p>
          </div>

          <div *ngIf="!isCarnival" class="in-progress-container_item">
            <img src="{{ assetsPath + 'reward.svg' }}" alt="" />
            <p class="caption1--regular">
              {{
                trans('DYNAMIC_INCENTIVE.LANDING.GOAL_COMPLETION', {
                  amount: activeChallenge.maxRewards,
                  currency: currencyShortName
                })
              }}
            </p>
          </div>

          <!-- carnival details -->
          <div *ngIf="isCarnival" class="in-progress-container_item">
            <img src="{{ assetsPath + 'box.svg' }}" alt="" />
            <p class="caption1--regular">
              {{
                trans('DYNAMIC_INCENTIVE.LANDING.CARNIVAL_ORDERS_FULFILLED', {
                  ordersAchieved: activeChallenge.challengeResult?.additionalAchievedOrders
                })
              }}
            </p>
          </div>

          <div *ngIf="isCarnival" class="in-progress-container_item">
            <img src="{{ assetsPath + 'reward.svg' }}" alt="" />
            <p class="caption1--regular">
              {{
                trans('DYNAMIC_INCENTIVE.LANDING.CARNIVAL_PER_ORDER', {
                  amount: activeChallenge.levels[0]?.rewardPerOrderExceedingLevelCap,
                  currency: currencyShortName
                })
              }}
            </p>
          </div>
        </div>
      </ng-container>

      <!-- SUCCESS OR FAIL -->
      <ng-template #finishedContainer>
        <div class="finished-container">
          <div class="finished-container_item">
            <p *ngIf="activeChallenge.status === 'ACHIEVED'" class="caption1--regular">
              {{
                trans('DYNAMIC_INCENTIVE.LANDING.WON_CHALLENGE', {
                  amount: activeChallenge.challengeResult?.rewards,
                  currency: currencyShortName
                })
              }}
            </p>

            <p *ngIf="activeChallenge.status === 'NOT_ACHIEVED'" class="caption1--regular">
              {{ trans('DYNAMIC_INCENTIVE.LANDING.FAILED_CHALLENGE') }}
            </p>
          </div>

          <div class="finished-container_item">
            <p class="caption1--regular">
              {{ trans('DYNAMIC_INCENTIVE.LANDING.STAY_TUNED') }}
            </p>
          </div>
        </div>
      </ng-template>
    </div>
  </div>
</div>

import { NgClass, NgIf } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { mixPanelEvent } from '@features/activities/data';
import { country } from '@features/country/data';
import { user } from '@features/user/data';
import { LocalizedComponent } from 'app/presentation/base/localized.component';
import { ProgressBarComponent } from '../../../../shared/components/progress-bar/progress-bar.component';
import { CashbackData } from '../presenter/challenges-widget.viewState';

@Component({
  selector: 'app-cashback',
  standalone: true,
  imports: [NgIf, NgClass, ProgressBarComponent],
  templateUrl: './cashback.component.html',
  styleUrls: ['./cashback.component.scss'],
})
export class CashbackComponent extends LocalizedComponent {
  @Output() toggleCashbackProgressCard = new EventEmitter<boolean>();

  @Input() cashback: CashbackData;

  public assetsPath = 'assets/img/incentive-program/';

  public userName = user.fullName?.split(' ')[0];

  public country = country.name;

  public get progress(): number {
    return (this.cashback.achievedOrders / this.cashback.targetOrders) * 100;
  }

  public onBannerClick(): void {
    const eventName = 'onboarding_order_tracking_banner_view_progress_click';
    const payload = {
      reward_type: 'cashback',
      progress_score: `${this.cashback.achievedOrders} من ${this.cashback.targetOrders}`,
    };

    mixPanelEvent(eventName, payload);

    this.toggleCashbackProgressCard.emit(true);
  }
}

<div class="challenges-widget">
  <ng-container *ngIf="viewState.challengesLoaded && viewState.challenges?.length > 0">
    <app-incentives [challenges]="viewState.challenges"></app-incentives>
    <app-tiering></app-tiering>
  </ng-container>

  <ng-container
    *ngIf="
      viewState.cashbackLoaded && !viewState.cashback.achieved && viewState.challenges?.length === 0
    "
  >
    <app-cashback
      [cashback]="viewState.cashback"
      (toggleCashbackProgressCard)="toggleCashbackProgressCard.emit($event)"
    ></app-cashback>
  </ng-container>
</div>

<div class="cashback-container" (click)="onBannerClick()">
  <ng-container *ngIf="!cashback.achieved; else finishedContainer">
    <div class="details-container">
      <img src="assets/img/treasure.svg" alt="Treasure" />
      <div>
        <p class="greeting caption1--regular">
          {{
            trans('CASHBACK.SUBTITLE', {
              name: userName
            })
          }}
        </p>
        <p class="body1--bold">
          {{
            trans('CASHBACK.TITLE', {
              amount: cashback.reward,
              currency: currencyShortName,
              targetOrders: cashback.targetOrders,
              country
            })
          }}
        </p>
      </div>
    </div>

    <div class="progress-container">
      <div class="header">
        <p class="caption2--medium">{{ trans('CASHBACK.YOUR_PROGRESS') }}</p>
        <img
          [ngClass]="{ flip: isRTL }"
          src="assets/img/incentive-program/arrow-right.svg"
          alt="arrow"
        />
      </div>

      <div class="content">
        <p class="caption1--bold">
          {{
            trans('CASHBACK.ORDERS_FULFILLED', {
              achievedOrders: cashback.achievedOrders,
              targetOrders: cashback.targetOrders
            })
          }}
        </p>
        <app-progress-bar [percentage]="progress"></app-progress-bar>
      </div>
    </div>
  </ng-container>

  <ng-template #finishedContainer>
    <div class="success-container">
      <div class="header">
        <p class="body1--bold">
          {{
            trans('CASHBACK.TITLE', {
              amount: cashback.reward,
              currency: currencyShortName,
              targetOrders: cashback.targetOrders,
              country
            })
          }}
        </p>
        <img src="assets/img/incentive-program/arrow-right.svg" alt="arrow" />
      </div>

      <div class="messages-list">
        <div class="message">
          <p class="caption1--regular">
            {{
              trans('CASHBACK.WON_CHALLENGE', {
                amount: cashback.reward,
                currency: currencyShortName
              })
            }}
          </p>
        </div>

        <div class="message">
          <p class="caption1--regular">
            {{ trans('CASHBACK.STAY_TUNED') }}
          </p>
        </div>
      </div>
    </div>
  </ng-template>
</div>

<div *ngIf="state === 'loading'" class="container loading-container d-flex flex-center">
  <loader [loading]="state === 'loading'" size="md" alt="loading" id="loading"></loader>
</div>

<div
  [ngClass]="downsizedBannerEnabled ? 'downsized-top-banner' : 'top-banner'"
  *ngIf="announcementsList.length > 0"
>
  <carousel
    [height]="'100%'"
    [cellWidth]="'100%'"
    [cellsToShow]="1"
    [dots]="true"
    [loop]="true"
    [autoplay]="true"
    [lightDOM]="true"
    [arrows]="showNavigationArrows"
    [pauseOnHover]="!isMobile"
  >
    <div
      class="carousel-cell"
      *ngFor="let item of announcementsList; let index = index"
      (click)="trackBannerClick()"
    >
      <img loading="lazy" class="banner-image" src="{{ item.img }}" *ngIf="!item.link" />
      <a *ngIf="item.link" [href]="item.link">
        <img loading="lazy" class="banner-image" src="{{ item.img }}" appHoverStyle />
      </a>
    </div>
  </carousel>
</div>

<div class="new-ui-container" *ngIf="!viewState.isLoading">
  <div class="title-container">
    <p class="caption2--medium">{{ trans('TIERING_SYSTEM_LANDING.YOUR_TIER') }}</p>
    <a [href]="loyaltyUrl" (click)="goToLoyaltyProgram()">
      <span class="caption2--medium">{{ trans('TIERING_SYSTEM_LANDING.BENEFITS') }}</span>
      <img
        [ngClass]="{ flip: isRTL }"
        src="assets/img/incentive-program/arrow-right.svg"
        alt="arrow icon"
      />
    </a>
  </div>

  <div class="tier-details">
    <img [src]="viewState.targetTier?.icon" alt="tier icon" />
    <p class="caption1--bold">
      {{
        trans('TIERING_SYSTEM_LANDING.CONFIRMED_ORDERS', {
          orders: viewState.targetTier?.targetOrderNumber,
          tier: localizedValue(viewState.targetTier?.name)
        })
      }}
    </p>
  </div>
  <app-progress-bar [percentage]="viewState.targetTier?.progress"></app-progress-bar>
</div>

import { NgClass, NgIf } from '@angular/common';
import { Component, inject } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { ProgressBarComponent } from 'app/presentation/shared/components/progress-bar/progress-bar.component';
import { BaseComponent } from 'src/app/presentation/base/base.component';
import { LOYALTY_URL, monthNameDateFormat } from 'src/app/presentation/shared/constants';
import { TieringPresenter } from './presentation/tiering.presenter';
import { TieringSideEffect } from './presentation/tiering.side-effects';
import { TieringViewEvent } from './presentation/tiering.view-events';
import { TieringViewState } from './presentation/tiering.view-state';

@Component({
  selector: 'app-tiering',
  standalone: true,
  imports: [TranslateModule, NgClass, ProgressBarComponent, NgIf],
  templateUrl: './tiering.component.html',
  styleUrls: ['./tiering.component.scss'],
  providers: [TieringPresenter],
})
export class TieringComponent extends BaseComponent<
  TieringPresenter,
  TieringViewState,
  TieringViewEvent,
  TieringSideEffect
> {
  public dateFormat = monthNameDateFormat;

  public presenter: TieringPresenter = inject(TieringPresenter);

  public loyaltyUrl = LOYALTY_URL;

  public onInit(): void {
    this.presenter.emitViewEvent({
      type: 'Init',
    });
  }

  public goToLoyaltyProgram(): void {
    this.presenter.emitViewEvent({
      type: 'ClickGoToLoyaltyProgram',
    });
  }
}

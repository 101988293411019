<app-tiering-widget *ngIf="isTieringSystemEnabled && !challengesWidgetEnabled"></app-tiering-widget>
<div class="products" *ngIf="!shouldDisplayDiscoverabilityFunnel">
  <app-onboarding-goal-progress-card
    *ngIf="toggleCurrentGoalProgress"
    (backdropClicked$)="toggleCurrentGoalProgressCard($event)"
  ></app-onboarding-goal-progress-card>
  <div class="container">
    <app-challenges-widget
      *ngIf="challengesWidgetEnabled"
      (toggleCashbackProgressCard)="toggleCurrentGoalProgressCard($event)"
    ></app-challenges-widget>
    <app-last-category-welcome-card></app-last-category-welcome-card>
    <section class="d-flex flex-column">
      <app-questionnaire-banner
        *ngIf="ksaOnboardingFlag && isUserEligibleForQuestionnaire"
      ></app-questionnaire-banner>
      <app-survey-form-banner
        *ngIf="surveyBannerEnabled"
        [addMarginTop]="!shouldDisplayDiscoverabilityFunnel"
      ></app-survey-form-banner>
      <app-top-banner></app-top-banner>
    </section>
    <section
      *ngIf="!challengesWidgetEnabled && isMerchantOnboardingEnabled"
      class="d-none d-lg-flex flex-column"
    >
      <app-onboarding-current-goal-progress
        (toggleCurrentGoalProgressCard)="toggleCurrentGoalProgressCard($event)"
      ></app-onboarding-current-goal-progress>
      <app-onboarding-learn-with-us></app-onboarding-learn-with-us>
    </section>
    <section class="d-flex flex-column mt-5">
      <div
        class="d-md-none d-flex flex-wrap justify-content-between category-header-container mb-1"
      >
        <div class="category-section-header heading2--bold">الاقسام</div>
        <div class="view-all body1--bold" (click)="goToCategories()">
          {{ 'HEADER.CATEGORIES_MEGA_MENU.VIEW_ALL' | translate }}
        </div>
      </div>
      <section class="d-flex flex-column"></section>
    </section>

    <section class="d-flex flex-column" *ngFor="let carouselData of carouselsData.topGroup">
      <app-products-carousel
        [carouselData]="carouselData"
        [isCatalogDataLoaded]="isCatalogDataLoaded"
      ></app-products-carousel>
    </section>

    <section class="d-flex flex-column" *ngFor="let carouselData of carouselsData.middleGroup">
      <app-products-carousel
        [carouselData]="carouselData"
        [isCatalogDataLoaded]="isCatalogDataLoaded"
      ></app-products-carousel>
    </section>
    <section class="d-flex flex-row justify-content-between" *ngIf="shouldShowSubBanners">
      <app-sub-banner
        *ngFor="let bannerData of subBannersData"
        [bannerData]="bannerData"
      ></app-sub-banner>
    </section>
    <section class="d-flex flex-column" *ngFor="let carouselData of carouselsData.bottomGroup">
      <app-products-carousel
        [carouselData]="carouselData"
        [isCatalogDataLoaded]="isCatalogDataLoaded"
      ></app-products-carousel>
    </section>
  </div>
</div>

<div class="category-items" *ngIf="shouldDisplayDiscoverabilityFunnel && isCatalogDataLoaded">
  <section
    *ngIf="!challengesWidgetEnabled && isMerchantOnboardingEnabled"
    class="d-none d-lg-flex flex-column"
  >
    <app-onboarding-current-goal-progress
      (toggleCurrentGoalProgressCard)="toggleCurrentGoalProgressCard($event)"
    ></app-onboarding-current-goal-progress>
    <app-onboarding-learn-with-us></app-onboarding-learn-with-us>
  </section>
  <app-last-category-welcome-card></app-last-category-welcome-card>
  <app-last-category></app-last-category>
</div>

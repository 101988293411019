import { NgClass, NgFor, NgIf } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { IvyCarouselModule } from 'angular-responsive-carousel';
import { GetFeatureFlagUsecase } from 'app/core/usecases/get-feature-flag.usecase';
import { FEATURE_FLAGS } from 'app/presentation/shared/constants';
import { MoengageService } from 'app/presentation/shared/services/mo-engage.service';
import { ResponsiveService } from 'src/app/presentation/shared/services/responsive.service';
import { UserService } from 'src/app/presentation/shared/services/user.service';
import { LoaderComponent } from '../../../shared/components/loader/loader.component';
import { HoverStyleDirective } from '../../../shared/directives/hover-style.directive';

@Component({
  selector: 'app-top-banner',
  templateUrl: './top-banner.component.html',
  styleUrls: ['./top-banner.component.scss'],
  standalone: true,
  imports: [NgIf, NgClass, IvyCarouselModule, NgFor, HoverStyleDirective, LoaderComponent],
})
export class TopBannerComponent implements OnInit {
  public announcementsList: any[];

  public showNavigationArrows: boolean;

  public isMobile: boolean;

  public state = 'idle';

  public downsizedBannerEnabled = false;

  constructor(
    private userService: UserService,
    private responsiveService: ResponsiveService,
    private _moEngageService: MoengageService,
    private getFeatureFlagUseCase: GetFeatureFlagUsecase,
  ) {
    this.announcementsList = [];
    this.showNavigationArrows = true;
  }

  ngOnInit(): void {
    this.getIsMobile();
    this.getAnnouncement();
    const screenSize = this.responsiveService.getScreenWidth();
    if (screenSize === 'xl') {
      this.showNavigationArrows = true;
    } else if (screenSize === 'lg') {
      this.showNavigationArrows = false;
    } else if (screenSize === 'md') {
      this.showNavigationArrows = false;
    } else if (screenSize === 'sm') {
      this.showNavigationArrows = false;
    } else {
      this.showNavigationArrows = false;
    }

    this.checkIfDownsizedBannerEnabled();
  }

  getAnnouncement(): void {
    this.state = 'loading';
    this.userService.getAnnouncement().subscribe({
      next: (res: any) => {
        this.announcementsList = res.data.filter(
          (announcement: any) => announcement.isMobile === this.isMobile,
        );
      },
      complete: () => {
        this.state = 'idle';
      },
    });
  }

  getIsMobile(): void {
    this.responsiveService.getMobileStatus().subscribe({
      next: (isMobile) => {
        this.isMobile = isMobile;
      },
    });
  }

  trackBannerClick(): void {
    this._moEngageService.trackEvent('User_Clicked_Banner', {
      Click_Type: 'Banner',
    });
  }

  private checkIfDownsizedBannerEnabled(): void {
    this.getFeatureFlagUseCase.execute(FEATURE_FLAGS.WEB_DOWNSIZED_BANNER).subscribe({
      next: (flag) => {
        this.downsizedBannerEnabled = flag;
      },
    });
  }
}

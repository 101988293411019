import { Injectable } from '@angular/core';
import { mixPanelEvent } from '@features/activities/data';
import { country } from '@features/country/data';
import { user } from '@features/user/data';
import { GetFeatureAttributeUsecase } from 'app/core/usecases/get-feature-attribute.usecase';
import { WEB_CHALLENGES_WIDGET } from 'app/presentation/shared/constants/feature-flags';
import { featureAttributeAssign } from 'app/presentation/shared/utilities/feature-attribute-assign.utility';
import { combineLatest } from 'rxjs';
import {
  MerchantLoyaltyProgressModel,
  TierModel,
} from 'src/app/core/domain/loyalty-program/loyalty-program.model';
import { GetLoyaltyProgressUseCase } from 'src/app/core/usecases/loyalty-program/get-progress.usecase';
import { GetLoyaltyTiersDataUseCase } from 'src/app/core/usecases/loyalty-program/get-tiers-data.usecase';
import { BasePresenter } from 'src/app/presentation/base/base.presenter';
import { TieringSideEffect } from './tiering.side-effects';
import { TieringViewEvent } from './tiering.view-events';
import { TieringViewState } from './tiering.view-state';

@Injectable({ providedIn: 'root' })
export class TieringPresenter extends BasePresenter<
  TieringViewState,
  TieringViewEvent,
  TieringSideEffect
> {
  constructor(
    private _getLoyaltyTiersDataUseCase: GetLoyaltyTiersDataUseCase,
    private _getLoyaltyProgressUseCase: GetLoyaltyProgressUseCase,
    private _getFeatureAttributeUseCase: GetFeatureAttributeUsecase,
  ) {
    super();
  }

  protected defaultViewState(): TieringViewState {
    return {
      challengesWidgetEnabled: false,
      targetTier: {
        name: { en: '', ar: '' },
        icon: '',
        targetOrderNumber: 0,
        progress: 0,
      },
      isLoading: true,
    };
  }

  protected onViewEvent(event: TieringViewEvent): void {
    switch (event.type) {
      case 'Init': {
        this.checkIfChallengesWidgetIsEnabled();

        const loyaltyTiersData$ = this._getLoyaltyTiersDataUseCase.execute();

        const loyaltyProgress$ = this._getLoyaltyProgressUseCase.execute();

        this.add(
          combineLatest([loyaltyTiersData$, loyaltyProgress$]).subscribe(
            ([loyaltyTiersData, loyaltyProgress]) => {
              this.merge({
                targetTier: this.getTargetTier(loyaltyTiersData, loyaltyProgress),
                isLoading: false,
              });
            },
          ),
        );

        break;
      }

      case 'ClickGoToLoyaltyProgram': {
        mixPanelEvent('tiering_go_to_loyalty_clicked');
        break;
      }
    }
  }

  private checkIfChallengesWidgetIsEnabled(): void {
    this._getFeatureAttributeUseCase.execute(WEB_CHALLENGES_WIDGET).subscribe({
      next: (attribute) => {
        this.merge({
          challengesWidgetEnabled: featureAttributeAssign(attribute, user.id, country.code),
        });
      },
    });
  }

  private getTargetTier(tiers: TierModel[], progress: MerchantLoyaltyProgressModel) {
    let targetIdx = progress.currentLoyaltyTierIndex + 1;

    // there's no tiers with index > 4
    if (targetIdx > 4) targetIdx = 4;

    const targetTier = tiers.find((tier) => tier.index === targetIdx);

    if (!targetTier) return;

    return {
      name: targetTier.displayName,
      icon: targetTier.icon,
      targetOrderNumber: targetTier.targetOrders,
      progress: (progress.achievedOrders / targetTier.targetOrders) * 100,
    };
  }
}

import { NgClass, NgIf } from '@angular/common';
import { Component, Input } from '@angular/core';
import { mixPanelEvent } from '@features/activities/data';
import { DynamicIncentiveProgramModel } from 'app/core/domain/dynamic-incentive-program.model';
import { LocalizedComponent } from 'app/presentation/base/localized.component';
import { CircularProgressBarComponent } from 'app/presentation/shared/components/circular-progress-bar/circular-progress-bar.component';
import { INCENTIVE_URL } from 'app/presentation/shared/constants';

@Component({
  selector: 'app-incentives',
  standalone: true,
  imports: [NgIf, NgClass, CircularProgressBarComponent],
  templateUrl: './incentives.component.html',
  styleUrls: ['./incentives.component.scss'],
})
export class IncentivesComponent extends LocalizedComponent {
  public assetsPath = 'assets/img/incentive-program/';

  public incentiveUrl = INCENTIVE_URL;

  public activeChallenge: DynamicIncentiveProgramModel;

  public remainingTime = {
    days: 0,
    hours: 0,
    minutes: 0,
  };

  public isCarnival = false;

  @Input() challenges: DynamicIncentiveProgramModel[];

  public constructor() {
    super();
  }

  public ngOnChanges(): void {
    this.setActiveChallenge();
    this.checkIfCarnival();
    this.calculateRemainingTime();
  }

  public get progressPercentage(): number {
    return (
      ((this.activeChallenge.challengeResult?.numOfAchievedOrders || 0) /
        this.activeChallenge.maxNumberOfOrders) *
      100
    );
  }

  public get inProgress(): boolean {
    return this.activeChallenge.status === 'IN_PROGRESS';
  }

  public get isOffered(): boolean {
    return this.activeChallenge.status === 'OFFERED';
  }

  private setActiveChallenge(): void {
    [this.activeChallenge] = this.challenges;
  }

  private checkIfCarnival(): void {
    const carnivalEnabled =
      (this.activeChallenge.levels[0]?.rewardPerOrderExceedingLevelCap || 0) > 0;

    if (!carnivalEnabled) this.isCarnival = false;

    const hasTimeToEnd = this.activeChallenge.endDate > new Date();

    const exceededBaseRewards =
      (this.activeChallenge.challengeResult?.rewards || 0) >
      (this.activeChallenge.challengeResult?.baseRewards || 0);

    if (hasTimeToEnd && exceededBaseRewards) this.isCarnival = true;
  }

  private calculateRemainingTime(): void {
    const start = new Date();
    const end = this.isOffered
      ? new Date(this.activeChallenge.expiryDate!)
      : new Date(this.activeChallenge.endDate);

    const differenceInMillis = end.getTime() - start.getTime();

    if (differenceInMillis < 0) return;

    const days = Math.floor(differenceInMillis / (1000 * 60 * 60 * 24));
    const hours = Math.floor((differenceInMillis % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((differenceInMillis % (1000 * 60 * 60)) / (1000 * 60));

    this.remainingTime = { days, hours, minutes };
  }

  public trackGoToIncentiveUrl(): void {
    mixPanelEvent('tiering_go_to_incentives_clicked');
  }
}

import { NgIf } from '@angular/common';
import { Component, EventEmitter, inject, Output } from '@angular/core';
import { BaseComponent } from 'app/presentation/base/base.component';
import { CashbackComponent } from './cashback/cashback.component';
import { IncentivesComponent } from './incentives/incentives.component';
import { ChallengesWidgetPresenter } from './presenter/challenges-widget.presenter';
import { ChallengesWidgetSideEffect } from './presenter/challenges-widget.sideEffects';
import { ChallengesWidgetViewEvents } from './presenter/challenges-widget.viewEvents';
import { ChallengesWidgetViewState } from './presenter/challenges-widget.viewState';
import { TieringComponent } from './tiering/tiering.component';

@Component({
  selector: 'app-challenges-widget',
  standalone: true,
  imports: [IncentivesComponent, TieringComponent, CashbackComponent, NgIf],
  templateUrl: './challenges-widget.component.html',
  styleUrls: ['./challenges-widget.component.scss'],
  providers: [ChallengesWidgetPresenter],
})
export class ChallengesWidgetComponent extends BaseComponent<
  ChallengesWidgetPresenter,
  ChallengesWidgetViewState,
  ChallengesWidgetViewEvents,
  ChallengesWidgetSideEffect
> {
  public assetsPath = 'assets/img/dynamic-incentive/';

  public presenter: ChallengesWidgetPresenter = inject(ChallengesWidgetPresenter);

  @Output() toggleCashbackProgressCard = new EventEmitter<boolean>();

  public constructor() {
    super();
  }

  public onInit(): void {
    this.presenter.emitViewEvent({
      type: 'Init',
    });
  }
}
